import React,{useState,useEffect,useRef} from 'react';
import { Box, HStack, Text, Heading, Tabs, TabList, TabPanels, Tab, TabPanel, Button, Link, Badge, Input, Select, Image } from '@chakra-ui/react'
import DetailLeftBar from '../components/DetailLeftBar';
import { FaRegBell, FaSearch } from "react-icons/fa";
import whatsapp_icon_img from '../assets/images/whatsapp_icon_img.png'
import chatbot_img from '../assets/images/chatbot_img.png'
import send_icon from '../assets/images/send_icon.png'
import { Switch } from 'antd';
import { fetchWrapper } from '../helpers';
import moment from 'moment';
import  {useInterval} from '../helpers/useInterval';
import UserChat from './UserChat'
import AgentChat from './AgentChat'
import Header from '../components/Header'
import MainLeftBar from '../components/MainLeftBar';
function ChatScreen(){
	const [isToggled, setIsToggled] = useState(false);

	const [statusId, setStatusId] = useState('assigned');
	const messageRef = useRef(null);

	const [message, setMessage] = React.useState('')
  	const handleChangeMsg = (event) => setMessage(event.target.value)

  	const messagesEndRef = useRef(null)
	const [list, setList] = useState([]);

	const [chatDetail, setChatDetail] = useState(false);

	 const [listMsg, setListMsg] = useState([]);
    const [listNewMsg, setListNewMsg] = useState([]);
    const [lastMsgId, setLastMsgId] = useState(0);

	const backendUrl = process.env.REACT_APP_API_URL

	const handleChange = () => {
	setIsToggled(!isToggled);
	};

	  
	useEffect(() => {
	    // Check if the token is present in local storage
	     LoadList('all');

	    	

	  }, []);
	//   var y = window.scrollY;
	useEffect(() => {
				// /console.log(messageRef.current.scrollHeight)

				 if(listMsg.length > 0){

				 		messageRef.current.scrollTo(0, messageRef.current.scrollHeight, "auto");
         }
    },[listMsg])  

	const scrollToBottom = () => {
   //messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

	const decodeToken =  (token) => {

		const userData = JSON.parse(token)

	    return userData
	};



	const LoadList = async(status_id,loading=true)=>{
		 setStatusId(status_id)
		
		
	
	   
	     //const userData = {}

		const url = `${process.env.REACT_APP_API_URL}/chat/chat-user-list`	
		
		const data =  await fetchWrapper.get(url)
		if(loading){
			setChatDetail(false)
		}
		
		setList(data.data);

	}
	const selChat = (rs) => {
		
		setChatDetail(rs);
		loadMsg(rs);

		//messageRef.current.scrollTo(0, messageRef.current.scrollHeight, "auto");
	}
	const loadMsg = async(rs) => {
		const rsData = rs
		let number = rsData.id
	     

	            const url = `${backendUrl}/chat/get-message`;

      const postData ={
       
       
        number:number

      }
      
      //setLoading(true)
      const token = {}
      const data  = await fetchWrapper.post(url,postData)
      //setLoading(false);
      if(data.length > 0){
            
            setLastMsgId( data[data.length - 1]._id)
      }
      
      setListMsg(data.reverse())

      //scrollToBottom()

	}
	const printUser = (login_id) => {
    if(login_id){
        const splitUser = login_id.split('@')
      return splitUser[0].substr(0,10);
    }
    return 'Unassigned';
    
} 


const sendMsg = async () => {

      if(message == ""){
        return false;
      }
      const rsData = chatDetail
      const to = rsData.id;// + '@s.whatsapp.net'
      
      

      const url = `${backendUrl}/chat/send-message`;



		 const msgUser = [];

       const postData = {number:to,msg:message}




      
       
       
      const data  = await fetchWrapper.post(url,postData).catch(error => {

          console.log(error)
      })


     
      
      setLastMsgId(data.data.id)


      //setListNewMsg([...listNewMsg,data.data])
      //setListMsg([...listMsg,data.data])
      setListMsg([...listMsg,data])

      //setListNewMsg([...listNewMsg,data.data])
     // setList(data)
      //loadList();
     
      setMessage("")



  }

  useInterval(() => {

        //console.log("Start clock")
  		if(chatDetail){
  			loadListNew();
  		}
         
         //scrollViewRef.current.scrollToEnd({ animated: true })
      }, 10000);


 useInterval(() => {

        //console.log("Start clock")
         LoadList(statusId,false);
         //scrollViewRef.current.scrollToEnd({ animated: true })
      }, 20000);


const loadListNew = async () => {
      //console.log('start')
	const rsData = chatDetail
      let number = rsData.id
      
      if(listMsg[listMsg.length-1]?.id){
		      const url = `${backendUrl}/chat/get-message-new`;
		     console.log(url)
		      const postData ={
		      
		       
		        number:number,
		        last_msg_id:listMsg[listMsg.length-1]?.id

		      }
		     
		      //setLoading(true)

		     
		      
		      const datax  = await fetchWrapper.post(url,postData)
		      //setLoading(false);
		      
		      if(datax.length > 0){
		        
		        //console.log(data)
		        //setLastMsgId(data[0]._id)

		       

		        //setList([...list,data])
		       
		        //console.log('start new')
		        //setListNewMsg([...listNewMsg,...datax])
		        setListMsg([...listMsg,...datax])

		        //scrollViewRef.current.scrollToEnd({ animated: true })
		        
		      }


      }
        
  }
  const handleScroll = (event) => {
    //console.log('Scroll position:', event.target.scrollHeight);
     //event.target.scrollTop = event.target.scrollHeight;
     
    // if(event.target.scrollTop == 0){
    // 	//loadOld()
    // }
  };

  const loadOld = async () => {
  	  const rsData = chatDetail
      let number = rsData.chat_whatsapp_number
      if(rsData.msg_channel == 'whatsapp'){
           number = number + '@s.whatsapp.net'
      }
      
      const url = `${backendUrl}/get-message-old?number=${number}`;

      const last_id = Math.min.apply(Math, listMsg.map(function (event) {
            return event._id;
        }));

     
      const postData ={
        msg_channel:rsData.msg_channel,
        last_msg_id:last_id,
        number:number

      }

    
      
      //setLoading(true)
      
      const data  = await fetchWrapper.post(url,postData)
      //return false;
      //setLoading(false);
      
      setListMsg([...data,...listMsg])

      //scrollViewRef.current.scrollTo({x: 0, y: 0, animated: true})
      
       
  }

	return(
		<>
			<HStack alignItems="top" gap="0px">
				<Box>
					<MainLeftBar />
				</Box>

			<Box className="chat_screen_bg">
				<HStack alignItems="top">
					<Box className="left_section">
						<Box className="chat_body_section">
							<Box className="tabs_section">
								<Tabs>
									<HStack justifyContent="space-between" className="tabs_head_section">
									  <TabList > 
									    <Tab onClick={() => LoadList('all')}>All (active) <Badge className="badge_box">1</Badge></Tab>
									    <Tab onClick={() => LoadList('assigned')}>Assigned</Tab>
									    <Tab onClick={() => LoadList('Pending') }>Pending</Tab>
									    <Tab onClick={() => LoadList('Spammed') }>Spam</Tab>
									    <Tab onClick={() => LoadList('completed') }>Closed</Tab>
									    <Tab onClick={() => LoadList('expire') }>Expired</Tab>
									    <Tab onClick={() => LoadList('exit') }>Exited</Tab>
									    <Tab onClick={() => LoadList('mentioned') }>Mentioned</Tab>
									  </TabList>

								  	<Box className="chat_header_section">
											<HStack justifyContent="flex-end">
												<Box me="10px">
												    <Switch
												      checkedChildren="Available"
												      unCheckedChildren="Not Available"
												      defaultChecked
												    />
												</Box>

												<Box me="10px">
													<FaRegBell size="20px" color="#000" />
												</Box>
											</HStack>
										</Box>
									 </HStack>
								  <TabPanels>
								   
								      <HStack className="chats_section">
								      	<Box className="all_chats_section">
								      		<Box className="head_section">
								      			<Box className="user_box">
													<Text>E</Text>

													<Box className="green_box"></Box>
												</Box>

												<Box className="search_section">
													<FaSearch size="18px" />

													<Input placeholder='Basic usage' />
												</Box>
								      		</Box>

								      		<Box className="body_section">
								      			<Box className="dropdown_section">
								      				<Select placeholder='Select option'>
													  <option value='option1'>All Channels</option>
													  <option value='option2'>All Channels</option>
													  <option value='option3'>All Channels</option>
													</Select>

													<Text className="head_text">All Conversations</Text>
								      			</Box>

								      			<Box style={{overflowY: 'scroll',height: 'calc(-266px + 100vh)'}}>
								      			{list?.map((rs,index)=>{
								      				return(
								      					<>
								      						<Box className="chat_user_section" onClick={() => selChat(rs)}>
											      				<HStack alignItems="center">
												      				<Box className="user_box bg_purple" me="5px">
												      					<Text>{rs?.from_name.slice(0,1)}</Text>

												      					<Image className="whatsapp_img" src={whatsapp_icon_img} />
												      				</Box>

												      				<Box>
												      					<Text className="username_text">{rs.from_name}</Text>
												      					<Text className="number_text">{rs.from_user_id.substring(0, 10)} ...</Text>
												      				</Box>
												      			</HStack>

											      				<Box>
											      					<Text className="status_text">Agent</Text>
											      					<Text className="time_text">{moment(rs.createdAt).format('LT')}</Text>
											      					
											      				</Box>
											      			</Box>
								      					</>
								      				)
								      				
								      			})}
								      			</Box>

								      			
								      		</Box>
								      	</Box>
								      	{chatDetail && 
								      	<Box className="chating_section">
								      		<Box className="head_section">
								      			<HStack >
								      			<Box w="50%"> 
								      				<Text><b>{chatDetail.from_name}</b></Text>
								      			</Box>
								      			<Box w="50%" textAlign='right'>
									      		
								      			</Box>
								      			</HStack>
								      		</Box>

								      		<Box className="all_message_section ref" onScroll={handleScroll} ref={messageRef}>

								      	
								      		{listMsg?.map((item,index)=> {
								      			return (
								      			<>
								      			{item.from_type == 0 ? 
								      				<UserChat item={item}  />

								      			:
								      				<AgentChat item={item}  />
								      				
								      			}

								      			</>

								      			)
								      				
								      			})}
								      			
								      		</Box>
								      		
								      		<Box className="message_field_section">
								      			<Input 
								      			 value={message}
        										onChange={handleChangeMsg}
								      			 placeholder='Type your message here' className="message_field" />

								      			<Button className="send_btn" onClick={() => sendMsg()}>
								      				<Image src={send_icon} />
								      			</Button>
								      		</Box>
								      		
								      	</Box>
								      	}
								      </HStack>
								    

								  
								  </TabPanels>
								</Tabs>
							</Box>
						</Box>
					</Box>

					<Box className="main_leftbar_section">
						<DetailLeftBar />
					</Box>
				</HStack>
			</Box>		
			</HStack>
		</>
	)
}

export default ChatScreen;