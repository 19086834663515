import React,{useState,useEffect} from 'react';
import { Box, HStack, Text, Heading, Tabs, TabList, TabPanels, Tab, 
TabPanel, Button, Link, Badge, Input, Select, Image } from '@chakra-ui/react'
import whatsapp_icon_img from '../assets/images/whatsapp_icon_img.png'
import chatbot_img from '../assets/images/chatbot_img.png'
import send_icon from '../assets/images/send_icon.png'
import moment from 'moment';
const AgentChat = ({item}) => {
const API_URL = process.env.REACT_APP_API_URL
let media_url = '';
  if(item.media_wa_type == 1){
     if(item.media_url == null){
        media_url = `${API_URL}/chat/media-downlaod?media_id=${item.media_id}&aa=${Math.random()}`
     }else{
        media_url = item.media_url
     }
  }

  if(item.media_wa_type == 2){
     if(item.media_url == ''){
        media_url = `${API_URL}/chat/media-downlaod?media_id=${item.media_id}`
     }else{
        media_url = item.media_url
     }
  }

  if(item.media_wa_type ==3){
     if(item.media_url == ''){
        media_url = `${API_URL}/chat/media-downlaod?media_id=${item.media_id}`
     }else{
        media_url = item.media_url
     }
  }

	return (


		<Box className="bot_message_section">
								      				<Box>
								      					

								      					
									      					
									      					<Text className="username_text">You</Text>
									      				

								      					<Box className="message_box">
								      						<Text className="message_text">{item.data}</Text>
								      						<Text className="time_text">{moment(item.send_timestamp).format('hh:mm a')}</Text>
								      					</Box>
								      				</Box>

								      				<Box className="img_box">
								      				
								      					<Image src={chatbot_img} />
								      				
								      				</Box>	
								      			</Box>
	)
}

export default AgentChat