import React,{useState,useEffect} from 'react';
import { Box, HStack, Text, Heading, Tabs, TabList, TabPanels, Tab, 
TabPanel, Button, Link, Badge, Input, Select, Image } from '@chakra-ui/react'
import whatsapp_icon_img from '../assets/images/whatsapp_icon_img.png'
import chatbot_img from '../assets/images/chatbot_img.png'
import send_icon from '../assets/images/send_icon.png'
import moment from 'moment';
import { FaRegBell, FaSearch } from "react-icons/fa";
const UserChat = ({item}) => {
	const API_URL = process.env.REACT_APP_API_URL
	let media_url = '';
  if(item.media_wa_type == 1){

     if(item.media_url == null){
        media_url = `${API_URL}/chat/media-downlaod?media_id=${item.media_id}`
       
     }else{
        media_url = item.media_url
     }
  }

  if(item.media_wa_type == 2){
     if(item.media_url == ''){
        media_url = `${API_URL}/chat/media-downlaod?media_id=${item.media_id}`
     }else{
        media_url = item.media_url
     }
  }

  if(item.media_wa_type ==3){
     if(item.media_url == ''){
        media_url = `${API_URL}/chat/media-downlaod?media_id=${item.media_id}`
     }else{
        media_url = item.media_url
     }
  }

	return (


		<Box className="user_message_section">
				<Box className="user_box bg_purple">
					<Text>{item?.from_name.slice(0,1)}</Text>

					<Image className="whatsapp_img" src={whatsapp_icon_img} />
				</Box>

				<Box>
					<Text className="username_text">{item.from_name}</Text>

					
				
					
					<Box className="message_box">
						<Text className="message_text">{item.data}</Text>
						<Text className="time_text">
						{moment(item.createdAt).format('hh:mm a')}
						</Text>
					</Box>

					


					


				</Box>
			</Box>
	)
}

export default UserChat