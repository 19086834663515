import React from 'react'
import { Box, HStack, Text, Heading, Tabs, TabList, TabPanels, Tab, TabPanel, Button, Link, Image } from '@chakra-ui/react'
import { FaRegClock, FaCheckCircle, FaHome, FaUser, FaEnvelope, FaBirthdayCake, FaPhoneAlt, FaInfoCircle, FaAngleRight } from "react-icons/fa";
import logo from '../assets/images/logo.png'
import analytics_icon from '../assets/images/analytics.svg'
import chat_manager from '../assets/images/chat-manager.svg'
import chatbot from '../assets/images/chatbot.svg'
import chatbot_builder from '../assets/images/chatbot-builder.svg'
import campaign_manager from '../assets/images/campign-manager.svg'
import whatsapp from '../assets/images/whatsapp.svg'
import contacts from '../assets/images/contacts.svg'
import star from '../assets/images/star.svg'
import billing from '../assets/images/billing.svg'
import settings from '../assets/images/settings.svg'

function MainLeftBar(){
	return(
		<>
			<Box className="nav_leftbar_section">
				<Box>
					<Box>
						<Link><Image src={logo} /></Link>
					</Box>
					
					<Box className="nav_link">
						<Link><Image src={analytics_icon} /></Link>
					</Box>

					<Box className="nav_link">
						<Link><Image src={chat_manager} /></Link>
					</Box>

					<Box className="nav_link">
						<Link><Image src={chatbot} /></Link>
					</Box>

					<Box className="nav_link">
						<Link><Image src={chatbot_builder} /></Link>
					</Box>

					<Box className="nav_link">
						<Link><Image src={campaign_manager} /></Link>
					</Box>

					<Box className="nav_link">
						<Link><Image src={whatsapp} /></Link>
					</Box>

					<Box className="nav_link">
						<Link><Image src={contacts} /></Link>
					</Box>
				</Box>

				<Box>
					<Box className="nav_link">
						<Link><Image src={star} /></Link>
					</Box>

					<Box className="nav_link">
						<Link><Image src={billing} /></Link>
					</Box>

					<Box className="nav_link">
						<Link><Image src={settings} /></Link>
					</Box>
				</Box>
			</Box>
		</>
	)
}

export default MainLeftBar;